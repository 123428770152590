import { ServerStatus } from '@interface/common';

export const UNAUTHORIZED: ServerStatus = {
  code: 401,
  message: 'Unauthorized',
};

export const UNPROCESSABLE_ENTITY: ServerStatus = {
  code: 422,
  message: 'Unprocessable entity',
};

export const INTERNAL_SERVER_ERROR: ServerStatus = {
  code: 500,
  message: 'Internal server error',
};
