import * as React from 'react';
import { MESSAGE } from '@core/constants';
import InfoIcon from '@assets/info.svg';
import SuccessIcon from '@assets/success.svg';
import WarningIcon from '@assets/warning.svg';
import ErrorIcon from '@assets/error.svg';
import { MessageType } from '@interface/common';


type IconProps = React.SVGAttributes<SVGAElement> & {
  type: MessageType;
}

export default function MessageIcon({ type, ...other }: IconProps) {
  switch (type) {
    case MESSAGE.SUCCESS:
      return <SuccessIcon {...other}/>;
    case MESSAGE.WARNING:
      return <WarningIcon {...other}/>;
    case MESSAGE.ERROR:
      return <ErrorIcon {...other}/>;
    case MESSAGE.INFO:
    default:
      return <InfoIcon {...other}/>;
  }
};
