import * as React from 'react';
import PlusIcon from '@assets/plus.svg';


export default function ToastCloseButton() {
  return (
    <div className="Toastify__close-button">
      <PlusIcon/>
    </div>
  );
};
